import { css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
import {
    Grid,
    Typography,
    Box,
    Container
} from '@material-ui/core';
import {
    CardElevation,
    ButtonDegrade,
    Oferta
} from '@findep/mf-landings-core';
import CarouselImg from '../img/CarouselImg.compilable'
import { navigate } from "../../helpers/queryNavigate"
import FelicidadesApp from '../elements/FelicidadesAppOld.compilable';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { CataloguesService } from '../../services/LandingV4'
import formatNumber from '../../helpers/formatNumber'
import NameCompany from '../../helpers/NameCompany'
import { getPeriodPlural } from '../../helpers/period'

import { withThemeProps } from '@findep/microfronts-core';

import PropTypes from 'prop-types'
import SeguroVida from '../pages/afi/SeguroVida'

const Felicidades = ({ companyName, pageContext, FelicidadesTitle, AppsTitle, TitleButtonEncuesta, TitleButton,
    TitleMonto,
    TitlePago,
    TitlePlazo,
    TitlePrimerPago,
    TitleSucursal
}) => {

    const [flujo, setFlujo] = useState({});
    const [survey, setSurvey] = useState('');
    const [congratulations, setCongratulations] = useState('')
    const [nameCompany, setNameCompany] = useState(NameCompany());
    const [fecha, setFecha] = useState('');

    useEffect(() => {
        let localFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        let localSurvey = sessionStorage.getItem('survey') || {}
        let congratulaionMessage

        setFlujo(localFlujo)

        async function getdata() {

            const catalogos = new CataloguesService()
            const catData = await catalogos.getSurveys(localFlujo.creditApplicationId)

            if (catData.status === 200) {
                localSurvey = 'true'
                setSurvey(localSurvey)
            }

            const data = await catalogos.getCongratulations(localFlujo.creditApplicationId)
            congratulaionMessage = data.data


            setCongratulations(congratulaionMessage)
            const { forceRedirect } = data.data
            if (forceRedirect !== null && forceRedirect !== undefined) {
                navigate(forceRedirect)
            }
        }
        try {
            getdata()
        } catch (e) {
            console.error(e)
        }

        var meses = pageContext.locale === "enUS" ? new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December") : new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
        let f = new Date(localFlujo?.preOferta?.oferta?.nextPayment)
        let arrFecha = `${f.getDate()}-${meses[f.getMonth()]}-${f.getFullYear()}`
        setFecha(arrFecha)

    }, [])

    const TextHeader = css`
    color: #698d21;
    text-align: center;
    font-weight: bold;
    font-size: 3em;
  `
    
    const textMessage = css`
        font-size: 1rem;
    `

    const styleTextEncuesta = css`
    padding: 1rem;
    color: rgba(0, 0, 0, 0.87);
`

    const rootPage = css`
    padding-left: 1rem;
    padding-right: 1rem;
`

    const props = [
        { icono: ArrowForwardIcon, titulo: `${TitleMonto}: `, texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.amount : 0 : 0)}` },
        { icono: ArrowForwardIcon, titulo: `${TitlePago}: `, texto: `${formatNumber(flujo ? flujo.preOferta ? flujo.preOferta.oferta.payment : 0 : 0)}` },
        { icono: ArrowForwardIcon, titulo: `${TitlePlazo}: `, texto: flujo ? flujo.preOferta ? `${flujo.preOferta.oferta.terms} ${getPeriodPlural(flujo?.preOferta?.oferta?.period, pageContext.locale === "enUS" ? "en" : "es")} ` : '' : '' },
        { icono: ArrowForwardIcon, titulo: `${TitlePrimerPago}: `, texto: flujo ? flujo.preOferta ? `${fecha}` : '' : '' },
    ]

    const propsSucursal = [
        { icono: ArrowForwardIcon, titulo: `${TitleSucursal}: `, texto: `${congratulations?.branch?.name}  ${congratulations?.branch?.city ? congratulations?.branch?.city : ''} Col. ${congratulations?.branch?.neighborhood} ${congratulations?.branch?.address} ${congratulations?.branch?.postalCode} ${congratulations?.branch?.state}`, sizeFontLi: '14px' },
    ]
    
    return (
        <Container justify="center" maxWidth="sm">
            <Grid container direction="column" justify="center" alignItems="center" css={rootPage}>
                <br />
                <br />
                <Typography color='primary'>
                    <h1 css={TextHeader}>{FelicidadesTitle}</h1>
                </Typography>
                <br />    
                <Oferta datos={props} colorOne="#FF9F01" colorTwo="#FA4616" />
                <br />
                <Typography css={styleTextEncuesta}>                    
                    <p css={textMessage}>&nbsp;</p>                    
                    {
                        congratulations.branch ? <Oferta datos={propsSucursal} colorOne="#FF9F01" colorTwo="#FA4616" /> : null
                    }
                </Typography>                                                
                <CardElevation>
                    <Typography>                        
                        <p style={{ fontSize: '1rem' }}>{AppsTitle}</p>
                    </Typography>

                    <FelicidadesApp company={companyName} />

                </CardElevation>
                <br />
                <SeguroVida entrada="felicidades" pageContext={pageContext}/>              
                <CarouselImg filename="1.svg" company={companyName} />
                <br />
            </Grid>
        </Container>

    )
}

export default withThemeProps(Felicidades, 'VDPNFelicidades')

Felicidades.propTypes = {
    FelicidadesTitle: PropTypes.string,
    AppsTitle: PropTypes.string,
    TitleButton: PropTypes.string,
}

Felicidades.defaultProps = {
    FelicidadesTitle: "¡Felicidades!",
    AppsTitle: "¡Ahorra tiempo! Maneja tu cuenta en donde te encuentres. ¡Descarga nuestra aplicación hoy!",
    TitleButtonEncuesta: "¿Nos ayudarías a contestar una breve encuesta para mejorar nuestro servicio?",
    TitleButton: "CONTESTAR ENCUESTA",
    TitleMonto: "Monto",
    TitlePago: "Pago",
    TitlePlazo: "Plazo",
    TitlePrimerPago: "Primer pago",
    TitleSucursal: "Sucursal"

}